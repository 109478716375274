<template>
  <div>
    <b-modal
      id="modal-print-konfirmasi"
      size="lg"
      centered
      scrollable
      title="Cetak Surat Surat"
      header-bg-variant="info"
      header-text-variant="light"
    >
      <b-row>
        <b-col cols="12" md="4" lg="4">
          <b-row class="mt-2 mb-2">
            <b-col>
              <b-button
                class="mr-1"
                block
                variant="secondary"
                @click="
                  seturl('Pengantar Konfirmasi.docx'),
                    (gen = !gen),
                    (name = 'Surat Konfirmasi Mitra.docx')
                "
              >
                Print Surat Konfirmasi Mitra
              </b-button>
            </b-col>
          </b-row>
          <b-row class="mt-2 mb-2">
            <b-col>
              <b-button
                class="mr-1"
                block
                variant="secondary"
                @click="
                  seturl('PengantarkeYBS.docx'),
                    (gen = !gen),
                    (name = 'Surat Konfirmasi YBS.docx')
                "
              >
                Print Surat Konfirmasi YBS
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button
          class="mr-1"
          variant="secondary"
          @click="$bvModal.hide('modal-print')"
        >
          Batal
        </b-button>
      </template>
    </b-modal>
    <generator :data="printData" :gen="gen" :url="url" :name="name" />
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import generator from "@/component/DOCX/docx_generator.vue";
import moment from "moment";
import terbilang from "@/component/DOCX/terbilang.js";
import "moment/locale/id";
export default {
  components: {
    generator,
  },
  name: "modalDetail",
  props: ["printData"],
  data() {
    return {
      ipBackend,
      gen: false,
      name: "",
      url: "",
    };
  },
  methods: {
    async seturl(x) {
      let vm = this;
      vm.url = this.ipBackend + "asset/docx/" + x;
      // console.log(this.printData, "lhaiki");
    },
  },
};
</script>
