<template>
  <div>
    <b-modal
      id="modal-alert"
      size="xl"
      centered
      title="Notifikasi"
      header-bg-variant="success"
      header-text-variant="light"
      hide-footer
    >
      <div>
        <b-tabs pills card fill>
          <b-tab active>
            <template #title>
              <div class="d-flex justify-content-between">
                <div>
                  <span>Ulang Tahun</span>
                </div>
                <div>
                  <b-badge variant="light">{{ ultah.length }}</b-badge>
                </div>
              </div>
            </template>
            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Per Halaman"
                  label-for="per-page-select"
                  label-cols-md="6"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="md"
                  ></b-form-select>
                </b-form-group>
              </b-col>

              <b-col md="6" offset-md="3">
                <b-form-group
                  label="Cari"
                  label-for="filter-input"
                  label-cols-md="3"
                  label-align-md="right"
                  label-size="md"
                >
                  <b-input-group size="md">
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Ketik disini untuk mencari ..."
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button
                        :disabled="!filter"
                        @click="filter = ''"
                        variant="danger"
                        >Hapus</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-table
                  :items="ultah"
                  :fields="fieldsUltah"
                  :current-page="currentPageUltah"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  stacked="md"
                  responsive
                  show-empty
                  small
                  @filtered="onFilteredUltah"
                  bordered
                  striped
                  hover
                  :busy="tableBusy"
                >
                  <template #cell(no)="item">
                    {{ item.index + 1 }}
                  </template>
                  <template #cell(tanggalLahirKaryawan)="item">
                    {{
                      moment(item.item.tanggalLahirKaryawan).format(
                        "DD-MM-YYYY"
                      )
                    }}
                  </template>
                  <template #cell(tanggalMulaiKerja)="item">
                    {{
                      moment(item.item.tanggalMulaiKerja).format("DD-MM-YYYY")
                    }}
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="5" offset-md="7">
                <b-pagination
                  v-model="currentPageUltah"
                  :total-rows="totalRowsUltah"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab active>
            <template #title>
              <div class="d-flex justify-content-between">
                <div>
                  <span>Pensiun 55</span>
                </div>
                <div>
                  <b-badge variant="light">{{ kontrak.length }}</b-badge>
                </div>
              </div>
            </template>
            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Per Halaman"
                  label-for="per-page-select"
                  label-cols-md="6"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="md"
                  ></b-form-select>
                </b-form-group>
              </b-col>

              <b-col md="6" offset-md="3">
                <b-form-group
                  label="Cari"
                  label-for="filter-input"
                  label-cols-md="3"
                  label-align-md="right"
                  label-size="md"
                >
                  <b-input-group size="md">
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Ketik disini untuk mencari ..."
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button
                        :disabled="!filter"
                        @click="filter = ''"
                        variant="danger"
                        >Hapus</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-table
                  :items="kontrak"
                  :fields="fieldsPensiun"
                  :current-page="currentPageKontrak"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  stacked="md"
                  responsive
                  show-empty
                  small
                  @filtered="onFilteredKontrak"
                  bordered
                  striped
                  hover
                  :busy="tableBusy"
                >
                  <template #cell(no)="item">
                    {{ item.index + 1 }}
                  </template>
                  <template #cell(tanggalMulaiKerja)="item">
                    {{
                      moment(item.item.tanggalMulaiKerja).format("DD-MM-YYYY")
                    }}
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="5" offset-md="7">
                <b-pagination
                  v-model="currentPageKontrak"
                  :total-rows="totalRowsKontrak"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                ></b-pagination>
              </b-col> </b-row
          ></b-tab>
          <b-tab>
            <template #title>
              <div class="d-flex justify-content-between">
                <div>
                  <span>Pensiun 56</span>
                </div>
                <div>
                  <b-badge variant="light">{{ permanen.length }}</b-badge>
                </div>
              </div>
            </template>
            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Per Halaman"
                  label-for="per-page-select"
                  label-cols-md="6"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="md"
                  ></b-form-select>
                </b-form-group>
              </b-col>

              <b-col md="6" offset-md="3">
                <b-form-group
                  label="Cari"
                  label-for="filter-input"
                  label-cols-md="3"
                  label-align-md="right"
                  label-size="md"
                >
                  <b-input-group size="md">
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Ketik disini untuk mencari ..."
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button
                        :disabled="!filter"
                        @click="filter = ''"
                        variant="danger"
                        >Hapus</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-table
                  :items="permanen"
                  :fields="fieldsPensiun"
                  :current-page="currentPagePermanen"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  stacked="md"
                  responsive
                  show-empty
                  small
                  @filtered="onFilteredPermanen"
                  bordered
                  striped
                  hover
                  :busy="tableBusy"
                >
                  <!-- <template #cell(no)="item">
                    {{ item.index + 1 }}
                  </template> -->
                  <template #cell(tanggalMulaiKerja)="item">
                    {{
                      moment(item.item.tanggalMulaiKerja).format("DD-MM-YYYY")
                    }}
                  </template>
                </b-table>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="5" offset-md="7">
                <b-pagination
                  v-model="currentPagePermanen"
                  :total-rows="totalRowsPermanen"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                ></b-pagination>
              </b-col> </b-row
          ></b-tab>
          <b-tab>
            <template #title>
              <div class="d-flex justify-content-between">
                <div>
                  <span>Habis Kontrak</span>
                </div>
                <div>
                  <b-badge variant="light">{{ habisKontrak.length }}</b-badge>
                </div>
              </div>
            </template>

            <b-alert dismissible fade :show="showing" :variant="variant">{{
              msg
            }}</b-alert>

            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Per Halaman"
                  label-for="per-page-select"
                  label-cols-md="6"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="md"
                  ></b-form-select>
                </b-form-group>
              </b-col>

              <b-col md="6" offset-md="3">
                <b-form-group
                  label="Cari"
                  label-for="filter-input"
                  label-cols-md="3"
                  label-align-md="right"
                  label-size="md"
                >
                  <b-input-group size="md">
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Ketik disini untuk mencari ..."
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button
                        :disabled="!filter"
                        @click="filter = ''"
                        variant="danger"
                        >Hapus</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-table
                  :items="habisKontrak"
                  :fields="fieldsHabis"
                  :current-page="currentPageHabis"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  stacked="md"
                  responsive
                  show-empty
                  small
                  @filtered="onFilteredHabis"
                  bordered
                  striped
                  hover
                  :busy="tableBusy"
                >
                  <template #cell(no)="item">
                    {{ item.index + 1 }}
                  </template>
                  <template #cell(tanggalMulaiKerja)="item">
                    {{
                      moment(item.item.tanggalMulaiKerja).format("DD-MM-YYYY")
                    }}
                  </template>
                  <template #cell(jatuhTempoKontrak)="item">
                    {{
                      moment(item.item.jatuhTempoKontrak).format("DD-MM-YYYY")
                    }}
                  </template>
                  <template #cell(statusKerjaKaryawan)="item">
                    <div v-if="item.item.statusKerjaKaryawan == 0">Kontrak</div>
                    <div v-else>Permanen</div>
                  </template>
                  <template #cell(actions)="item">
                    <b-button
                      variant="secondary"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Kirim Surat Konfirmasi'"
                      @click="kirimKonfirm(item.item)"
                      ><CIcon name="cil-print" /> {{ item.actions }}</b-button
                    >
                  </template>
                </b-table>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="5" offset-md="7">
                <b-pagination
                  v-model="currentPageHabis"
                  :total-rows="totalRowsHabis"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                ></b-pagination>
              </b-col> </b-row
          ></b-tab>
          <b-tab>
            <template #title>
              <div class="d-flex justify-content-between">
                <div>
                  <span>Surat Konfirmasi</span>
                </div>
                <div>
                  <b-badge variant="light">{{
                    suratKonfirmasi.length
                  }}</b-badge>
                </div>
              </div>
            </template>

            <b-alert dismissible fade :show="showing" :variant="variant">{{
              msg
            }}</b-alert>

            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Per Halaman"
                  label-for="per-page-select"
                  label-cols-md="6"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="md"
                  ></b-form-select>
                </b-form-group>
              </b-col>

              <b-col md="6" offset-md="3">
                <b-form-group
                  label="Cari"
                  label-for="filter-input"
                  label-cols-md="3"
                  label-align-md="right"
                  label-size="md"
                >
                  <b-input-group size="md">
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Ketik disini untuk mencari ..."
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button
                        :disabled="!filter"
                        @click="filter = ''"
                        variant="danger"
                        >Hapus</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-table
                  :items="suratKonfirmasi"
                  :fields="fieldsKonfirmasi"
                  :current-page="currentPageKonfirmasi"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  stacked="md"
                  responsive
                  show-empty
                  small
                  @filtered="onFilteredKonfirmasi"
                  bordered
                  striped
                  hover
                  :busy="tableBusy"
                >
                  <template #cell(no)="item">
                    {{ item.index + 1 }}
                  </template>
                  <template #cell(actions)="item">
                    <b-button
                      variant="secondary"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Print Surat'"
                      v-b-modal.modal-print-konfirmasi
                      @click="setPrint(item.item)"
                      ><CIcon name="cil-print" />{{ item.actions }}</b-button
                    >

                    <b-button
                      variant="primary"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Kirim Surat'"
                      @click="changeStatus(item.item)"
                      ><CIcon name="cil-print" />{{ item.actions }}</b-button
                    >

                    <b-button
                      v-if="item.item.statusKonfirmasi != 0"
                      variant="warning"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Edit Data'"
                      v-b-modal.modal-edit
                      @click="dataKonfirmasi = item.item"
                      ><CIcon name="cil-pencil" /> {{ item.actions }}</b-button
                    >
                  </template>
                </b-table>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="5" offset-md="7">
                <b-pagination
                  v-model="currentPageKonfirmasi"
                  :total-rows="totalRowsKonfirmasi"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                ></b-pagination>
              </b-col> </b-row
          ></b-tab>
        </b-tabs>
      </div>
      <div>
        <hr />
        <div class="d-flex justify-content-between">
          <div>
            <b-form-checkbox
              id="checkbox-1"
              v-model="status"
              name="checkbox-1"
              :value="false"
              :unchecked-value="true"
              @change="$store.commit('set_notif', status)"
            >
              Jangan tampilkan notifikasi lagi
            </b-form-checkbox>
          </div>
          <div>
            <b-button variant="secondary" @click="$bvModal.hide('modal-alert')">
              Batal
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>
    <modalEditKonfirmasi
      :data="dataKonfirmasi"
      @alertFromChild="triggerAlert($event)"
    />
    <modalPrintKonfirmasi :printData="printData" />
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import moment from "moment";
moment.locale("id");
import modalEditKonfirmasi from "./modalEditKonfirmasi.vue";
import modalPrintKonfirmasi from "./modalPrintKonfirmasi.vue";
export default {
  name: "modalNotif",
  props: [],
  components: {
    modalEditKonfirmasi,
    modalPrintKonfirmasi,
  },
  data() {
    return {
      moment,
      dataKonfirmasi: [],
      ultah: [],
      kontrak: [],
      permanen: [],
      habisKontrak: [],
      suratKonfirmasi: [],
      printData: null,
      msg: "",
      showing: false,
      variant: "",
      status: true,
      items: [
        {
          no: "-",
          namaKaryawan: "-",
          jenisKelamin: "-",
          alamatKaryawan: "-",
          emailKaryawan: "-",
          noTelepon: "-",
          statusKaryawan: "-",
          tanggalMulaiKerja: "-",
        },
      ],
      fieldsHabis: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "namaKaryawan",
          label: "Nama",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "jenisKelaminKaryawan",
          label: "Gender",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        // {
        //   key: "alamatKaryawan",
        //   label: "Alamat",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-left",
        // },
        // {
        //   key: "emailKaryawan",
        //   label: "Email",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-left",
        // },

        {
          key: "statusKerjaKaryawan",
          label: "Status Kerja",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "tanggalMulaiKerja",
          label: "Mulai Kerja",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "jatuhTempoKontrak",
          label: "Habis Kontrak",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "actions",
          label: "Actions",
          class: "text-center",
        },
      ],
      fieldsKonfirmasi: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "nomorSuratKonfirmasi",
          label: "No. Surat Konfirmasi",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "namaKaryawan",
          label: "Nama",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "statusnya",
          label: "Status",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "tglDibuat",
          label: "Tanggal Surat Dibuat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "tglDibalas",
          label: "Tanggal Surat Dibalas",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "actions",
          label: "Actions",
          class: "table-option-4 text-center",
        },
      ],
      fieldsPensiun: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "namaKaryawan",
          label: "Nama",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "jenisKelaminKaryawan",
          label: "Gender",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "alamatKaryawan",
          label: "Alamat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        // {
        //   key: "emailKaryawan",
        //   label: "Email",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-left",
        // },
        {
          key: "noHpKaryawan",
          label: "No. Telepon",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "statusKerja",
          label: "Status Karyawan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "tanggalMulaiKerja",
          label: "Mulai Kerja",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
      ],
      fieldsUltah: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "namaKaryawan",
          label: "Nama",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "jenisKelaminKaryawan",
          label: "Gender",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "alamatKaryawan",
          label: "Alamat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        // {
        //   key: "emailKaryawan",
        //   label: "Email",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-left",
        // },
        {
          key: "tanggalLahirKaryawan",
          label: "Tanggal Lahir",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        // {
        //   key: "statusKerja",
        //   label: "Status Karyawan",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-left",
        // },

        {
          key: "tanggalMulaiKerja",
          label: "Mulai Kerja",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
      ],
      fieldsPensiun: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "namaKaryawan",
          label: "Nama",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "jenisKelaminKaryawan",
          label: "Gender",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "alamatKaryawan",
          label: "Alamat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        // {
        //   key: "emailKaryawan",
        //   label: "Email",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-left",
        // },
        // {
        //   key: "noHpKaryawan",
        //   label: "No. Telepon",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-left",
        // },

        // {
        //   key: "statusKerja",
        //   label: "Status Karyawan",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-left",
        // },

        {
          key: "tanggalMulaiKerja",
          label: "Mulai Kerja",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
      ],

      totalRowsUltah: 1,
      currentPageUltah: 1,
      totalRowsKontrak: 1,
      currentPageKontrak: 1,
      totalRowsPermanen: 1,
      currentPagePermanen: 1,
      totalRowsHabis: 1,
      currentPageHabis: 1,
      totalRowsKonfirmasi: 1,
      currentPageKonfirmasi: 1,
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: ['namaKaryawan'],
      tableBusy: false,
    };
  },
  mounted() {
    if (this.$store.state.notif) {
      this.getNotif();
    }
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  methods: {
    onFilteredUltah(filteredItems) {
      this.totalRowsUltah = filteredItems.length;
      this.currentPageUltah = 1;
    },
    onFilteredKontrak(filteredItems) {
      this.totalRowsKontrak = filteredItems.length;
      this.currentPageKontrak = 1;
    },
    onFilteredPermanen(filteredItems) {
      this.totalRowsPermanen = filteredItems.length;
      this.currentPagePermanen = 1;
    },
    onFilteredHabis(filteredItems) {
      this.totalRowsHabis = filteredItems.length;
      this.currentPageHabis = 1;
    },
    onFilteredKonfirmasi(filteredItems) {
      this.totalRowsKonfirmasi = filteredItems.length;
      this.currentPageKonfirmasi = 1;
    },
    async getNotif() {
      let vm = this;
      let ultah = await axios.get(ipBackend + "dataKaryawan/notifUlangTahun");
      vm.ultah = ultah.data.data.map((item, index) => {
        return { ...item, no: index + 1 };
      });
      vm.totalRowsUltah = vm.ultah.length;
      let kontrak = await axios.get(ipBackend + "dataKaryawan/notifKontrak");
      vm.kontrak = kontrak.data.data.map((item, index) => {
        return { ...item, no: index + 1 };
      });
      vm.totalRowsKontrak = vm.kontrak.length;
      let permanen = await axios.get(ipBackend + "dataKaryawan/notifPermanen");
      vm.permanen = permanen.data.data.map((item, index) => {
        return { ...item, no: index + 1 };
      });
      vm.totalRowsPermanen = vm.permanen.length;
      let habis = await axios.get(ipBackend + "dataKaryawan/notifHabisKontrak");
      vm.habisKontrak = habis.data.data.map((item, index) => {
        return { ...item, no: index + 1 };
      });
      vm.totalRowsHabis = vm.habisKontrak.length;
      // console.log(vm.habisKontrak, "ini habis kontrak");
      vm.suratKonfirmasi = [];
      let listKonfirmasi = await axios.get(ipBackend + "suratKonfirmasi/list");
      for (let i = 0; i < listKonfirmasi.data.data.length; i++) {
        let x = listKonfirmasi.data.data[i];
        if (x.statusKonfirmasi == 0 || x.statusKonfirmasi == 1) {
          vm.suratKonfirmasi.push(x);
        }
      }
      for (let i = 0; i < vm.suratKonfirmasi.length; i++) {
        let x = vm.suratKonfirmasi[i];
        if (x.statusKonfirmasi == 0) {
          x.statusnya = "Belum Dikirim";
        } else if (x.statusKonfirmasi == 1) {
          x.statusnya = "Dikirim (Belum Dibalas)";
        }
        x.tglDibuat = moment(x.tanggalDibuatSurat).format("DD-MM-YYYY");
        if (x.tanggalDibalasSurat) {
          x.tglDibalas = moment(x.tanggalDibalasSurat).format("DD-MM-YYYY");
        }
      }
      vm.totalRowsKonfirmasi = vm.suratKonfirmasi.length;
      vm.$bvModal.show("modal-alert");
    },
    async setPrint(x) {
      // console.log(x, "ini xxxxxxxxx");
      let vm = this;
      let printdata = await axios.get(
        ipBackend + "poolKontrak/pengantarYBS/" + x.dataKaryawanId
      );
      // console.log(printdata.data.data, "ini konfirmasinya boss");
      vm.printData = printdata.data.data[0];
      vm.printData.tanggal = moment().format("LL");
      vm.printData.tanggalLahirKaryawanPrint = moment(
        vm.printData.tanggalLahirKaryawan
      ).format("LL");
    },
    changeStatus(x) {
      let vm = this;
      // console.log(x);
      let data = {};
      data.id = x.suratKonfirmasiId;
      data.statusKonfirmasi = 1;
      axios
        .post(ipBackend + "suratKonfirmasi/update", data)
        .then((res) => {
          // console.log(res, "ini ress");
          if (res.data.message == "sukses") {
            vm.variant = "success";
            vm.msg = "BERHASIL MENGIRIM SURAT KONFIRMASI";
            vm.showing = true;
            vm.getNotif();
            setTimeout(() => {
              vm.showing = false;
            }, 4000);
          } else {
            vm.variant = "danger";
            vm.msg = _.toUpper(res.data.message);
            vm.showing = true;
            setTimeout(() => {
              vm.showing = false;
            }, 4000);
          }
        })
        .catch((err) => {
          // console.log(err);
          vm.variant = "danger";
          vm.msg = "TERJADI KESALAHAN PADA SERVER";
          vm.showing = true;
          setTimeout(() => {
            vm.showing = false;
          }, 4000);
        });
    },
    kirimKonfirm(x) {
      let vm = this;
      // console.log(x);
      let data = {};
      data.nomorSuratKontrak = x.nomorSurat;
      data.tanggalDibuatSurat = moment(new Date())
        .startOf("day")
        .format("YYYY-MM-DD");
      data.statusKonfirmasi = 0;
      data.poolKontrakId = x.poolKontrakId;
      // console.log(data, 'ini datanya');
      axios
        .post(ipBackend + "suratKonfirmasi/register", data)
        .then((res) => {
          console.log(res, "ini ress");
          if (res.data.message == "sukses") {
            vm.variant = "success";
            vm.msg = "BERHASIL MEMBUAT SURAT KONFIRMASI";
            vm.showing = true;
            setTimeout(() => {
              vm.showing = false;
            }, 4000);
          } else {
            vm.variant = "danger";
            vm.msg = _.toUpper(res.data.message);
            vm.showing = true;
            setTimeout(() => {
              vm.showing = false;
            }, 4000);
          }
        })
        .catch((err) => {
          // console.log(err);
          vm.variant = "danger";
          vm.msg = "TERJADI KESALAHAN PADA SERVER";
          vm.showing = true;
          setTimeout(() => {
            vm.showing = false;
          }, 4000);
        });
    },
    triggerAlert(event) {
      let vm = this;
      vm.showing = event.showing;
      vm.variant = event.variant;
      vm.msg = event.msg;
      vm.getNotif();
      setTimeout(() => {
        vm.showing = false;
      }, 4000);
    },
  },
};
</script>
