<template>
  <div>
    <b-modal
      id="modal-edit"
      size="lg"
      centered
      title="Edit Surat Konfirmasi"
      header-bg-variant="warning"
      header-text-variant="light"
    >
      <b-form>
        <b-form-group label="Status Konfirmasi" label-cols-md="3">
          <b-form-select
            v-model="data.statusKonfirmasi"
            :options="options"
          ></b-form-select>
        </b-form-group>
        <b-form-group label="Tanggal Dibalas" label-cols-md="3">
          <date-picker
            style="width: 100%"
            v-model="data.tanggalDibalasSurat"
            :placeholder="data.tglDibalas"
          ></date-picker>
        </b-form-group>
      </b-form>
      <!-- <img :src="src1" /> -->
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-edit')">
          Tutup
        </b-button>
        <b-button variant="primary" @click="simpan()">
          {{ button }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
export default {
  name: "modalEdit",
  props: ["data"],
  components: {
    DatePicker,
  },
  data() {
    return {
      busy: false,
      button: "Simpan",
      options: [
        { value: null, text: "Silahkan Pilih" },
        { value: 1, text: "Dikirim (Belum Dibalas)" },
        { value: 2, text: "Sudah Dibalas" },
      ],
    };
  },
  methods: {
    simpan() {
      let vm = this;
      vm.button = "Mohon Tunggu";
      let hasil = {};
      hasil.id = vm.data.suratKonfirmasiId;
      hasil.statusKonfirmasi = vm.data.statusKonfirmasi;
      hasil.tanggalDibalasSurat = vm.data.tanggalDibalasSurat;
      // console.log(hasil);
      axios
        .post(ipBackend + "suratKonfirmasi/update", hasil)
        .then((res) => {
          // console.log(res, "ini ya om");
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENGEDIT DATA",
              showing: true,
            });
            this.$bvModal.hide("modal-edit");
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
            this.$bvModal.hide("modal-edit");
          }
        })
        .catch((err) => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
          this.$bvModal.hide("modal-edit");
        });
    },
  },
};
</script>
