<template>
  <div>
    <b-modal
      id="modal-detail"
      size="xl"
      centered
      title="Detail List Karyawan"
      header-bg-variant="info"
      header-text-variant="light"
    >
      <b-row>
        <b-col md="3">
          <b-form-group
            label="Per Halaman"
            label-for="per-page-select"
            label-cols-md="6"
            label-align-md="left"
            label-size="md"
            style="background-color: "
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="md"
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col md="6" offset-md="3">
          <b-form-group
            label="Cari"
            label-for="filter-input"
            label-cols-md="3"
            label-align-md="right"
            label-size="md"
          >
            <b-input-group size="md">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Ketik disini untuk mencari ..."
              ></b-form-input>

              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                  variant="danger"
                  >Hapus</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-table
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            stacked="md"
            responsive
            show-empty
            small
            @filtered="onFiltered"
            bordered
            striped
            hover
            :busy="tableBusy"
          >
          </b-table>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="5" offset-md="7">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
          ></b-pagination>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-detail')">
          Batal
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import moment from "moment";
moment.locale("id");
export default {
  name: "modalDetail",
  props: ["data", "detail"],
  data() {
    return {
      items: [
        {
          no: "-",
          namaKaryawan: "-",
          jenisKelamin: "-",
          alamatKaryawan: "-",
          emailKaryawan: "-",
          noTelepon: "-",
          statusKaryawan: "-",
          tglMulaiKerja: "-",
        },
      ],
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "namaKaryawan",
          label: "Nama",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "jenisKelaminKaryawan",
          label: "Jenis Kelamin",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "alamatKaryawan",
          label: "Alamat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "emailKaryawan",
          label: "Email",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "noHpKaryawan",
          label: "No. Telepon",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "statusKerja",
          label: "Status Karyawan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "tglMulai",
          label: "Tanggal Mulai Kerja",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      tableBusy: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  watch: {
    detail: function (newVal, oldVal) {
      if (oldVal != newVal) {
        this.getDetail();
      }
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getDetail() {
      let vm = this;
      let id = vm.data.masterDivisiId;
      // console.log(vm.data, "ini id");
      let listDetail = await axios.get(
        ipBackend + "dataKaryawan/listByMasterDivisiId/" + id
      );
      vm.items = listDetail.data.data;
      //ForEach diganti
      // this.items.forEach((element, index) => {
      //   let x = this.items[index];
      //   if (x.statusKerjaKaryawan == 0) {
      //     x.statusKerja = "Kontrak";
      //   } else if (x.statusKerjaKaryawan == 1) {
      //     x.statusKerja = "Permanen";
      //   }
      //   x.no = index + 1;
      //   x.tglMulai = moment(x.tanggalMulaiKerja).format("LL");
      // });
      for (let i = 0; i < this.items.length; i++) {
        this.items[i].no = i + 1;
        this.items[i].tglMulai = moment(this.items[i].tanggalMulaiKerja).format(
          "LL"
        );
        if (this.items[i].statusKerjaKaryawan == 0) {
          this.items[i].statusKerja = "Kontrak";
        } else if (this.items[i].statusKerjaKaryawan == 1) {
          this.items[i].statusKerja = "Permanen";
        }
      }
      // console.log(listDetail, "ini itemssssnyaaaaaa");
    },
  },
};
</script>
