<template>
  <div>
    <b-row>
      <b-col cols="6" md="4" lg="4">
        <b-card
          bg-variant="primary"
          text-variant="white"
          title="Jumlah Pelamar Yang Mendaftar dan Belum Diverifikasi "
        >
          <b-card-text>
            <h1>
              <strong v-if="belum == 0">-</strong>
              <strong v-else>{{ belum }}</strong>
            </h1>
          </b-card-text>
        </b-card>
      </b-col>

      <b-col cols="6" md="4" lg="4">
        <b-card
          bg-variant="warning"
          text-variant="white"
          title="Jumlah Pelamar Yang Belum Melengkapi Syarat Administrasi "
        >
          <b-card-text>
            <h1>
              <strong v-if="verif == 0">-</strong>
              <strong v-else>{{ verif }}</strong>
            </h1>
          </b-card-text>
        </b-card>
      </b-col>

      <b-col cols="6" md="4" lg="4">
        <b-card
          bg-variant="success"
          text-variant="white"
          title="Jumlah Pelamar Dalam Yang Telah Masuk Tahap Seleksi "
        >
          <b-card-text>
            <h1>
              <strong v-if="lengkap == 0">-</strong>
              <strong v-else>{{ lengkap }}</strong>
            </h1>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" md="12" lg="12">
        <b-card header-tag="header" header-bg-variant="success">
          <template #header>
            <h5 class="mb-0" style="color: #fff">
              <strong>Total Karyawan </strong>
            </h5>
          </template>

          <b-row>
            <b-col md="3" lg="3">
              <b-row>
                <b-col cols="12" md="12" lg="12">
                  <b-card
                    bg-variant="warning"
                    text-variant="white"
                    title="Total Karyawan"
                  >
                    <b-card-text>
                      <h1>
                        <strong v-if="totalKaryawan == 0">-</strong>
                        <strong v-else>{{ totalKaryawan }}</strong>
                      </h1>
                    </b-card-text>
                  </b-card>
                </b-col>
              </b-row>

              <!-- <b-row>
                <b-col cols="12" md="12" lg="12">
                  <b-card
                    bg-variant="warning"
                    text-variant="white"
                    title="Total Karyawan Asing"
                  >
                    <b-card-text>
                      <h1>
                        <strong v-if="karyawanWNA == 0">-</strong>
                        <strong v-else>{{ karyawanWNA }}</strong>
                      </h1>
                    </b-card-text>
                  </b-card>
                </b-col>
              </b-row> -->
            </b-col>
            <b-col md="9" lg="9">
              <b-row>
                <b-col md="4">
                  <b-form-group
                    label="Per Halaman"
                    label-for="per-page-select"
                    label-cols-md="6"
                    label-align-md="left"
                    label-size="md"
                    style="background-color: "
                  >
                    <b-form-select
                      id="per-page-select"
                      v-model="perPage"
                      :options="pageOptions"
                      size="md"
                    ></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="6" offset-md="2">
                  <b-form-group
                    label="Cari"
                    label-for="filter-input"
                    label-cols-md="3"
                    label-align-md="right"
                    label-size="md"
                  >
                    <b-input-group size="md">
                      <b-form-input
                        id="filter-input"
                        v-model="filter2"
                        type="search"
                        placeholder="Ketik disini untuk mencari ..."
                      ></b-form-input>

                      <b-input-group-append>
                        <b-button
                          :disabled="!filter2"
                          @click="filter2 = ''"
                          variant="danger"
                          >Hapus</b-button
                        >
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" md="12" lg="12">
                  <b-table
                    :items="perDivisi"
                    :fields="fields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter="filter2"
                    :filter-included-fields="filterOn2"
                    stacked="md"
                    responsive
                    show-empty
                    small
                    @filtered="onFiltered2"
                    bordered
                    striped
                    hover
                    :busy="tableBusy"
                  >
                    <template #cell(no)="item">
                      {{ item.index + 1 }}
                    </template>
                    <template #cell(actions)="item">
                      <b-button
                        variant="info"
                        v-c-tooltip.hover.click="'Detail'"
                        v-b-modal.modal-detail
                        @click="(data = item.item), (detail = !detail)"
                        ><CIcon name="cil-info" /> {{ item.actions }}</b-button
                      >
                    </template>
                  </b-table>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="5" offset-md="7">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="fill"
                    size="sm"
                  ></b-pagination>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <Popup />
    <modal-detail-karyawan :data="data" :detail="detail" />
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import modalDetailKaryawan from "@/component/hcms/dashboard/modalDetailKaryawan.vue";
import Popup from "@/component/hcms/dashboard/modalAlert.vue"
export default {
  components: {
    modalDetailKaryawan,
    Popup
  },
  name: "DashboardHcms",
  data() {
    return {
      belum: 0,
      verif: 0,
      lengkap: 0,
      karyawanWNA: 0,
      totalKaryawan: 0,
      perDivisi: [],
      detail: false,
      data: "",
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "namaDivisi",
          label: "Divisi",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "totalKaryawan",
          label: "Total",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "actions",
          label: "Actions",
          class: "table-option-2 text-center",
        },
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      filter2: null,
      filterOn2: [],
      tableBusy: false,
    };
  },
  created() {
    this.getData();
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onFiltered2(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getData() {
      let vm = this;
      let listBelum = await axios.get(
        ipBackend + "postLoker/listByStatus/" + 0
      );
      vm.belum = listBelum.data.data.length;

      let listVerif = await axios.get(
        ipBackend + "postLoker/listByStatus/" + 1
      );
      vm.verif = listVerif.data.data.length;

      let listLengkap = await axios.get(
        ipBackend + "postLoker/listByStatus/" + 2
      );
      vm.lengkap = listLengkap.data.data.length;

      // let listKaryawanWNA = await axios.get(
      //   ipBackend + "dataKaryawan/listKaryawanWNA"
      // );
      // vm.karyawanWNA = listKaryawanWNA.data.data.length;

      let totalKaryawan = await axios.get(
        ipBackend + "dataKaryawan/totalKaryawan"
      );
      vm.totalKaryawan = totalKaryawan.data.data[0].totalKaryawan;

      let perDivisi = await axios.get(
        ipBackend + "dataKaryawan/listTotalKaryawanPerDivisi"
      );
      vm.perDivisi = perDivisi.data.data;
    },
  },
};
</script>
